@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Outfit", serif;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
::-webkit-scrollbar {
  width: 6px;  /* Width of the scrollbar */
  height: 5px; /* Height of the horizontal scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
  border-radius: 10px;  /* Rounded corners for the track */
}

::-webkit-scrollbar-thumb {
  background: #888;  /* Scrollbar thumb color */
  border-radius: 10px; /* Rounded corners for the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #7d7d7d;  /* Thumb color on hover */
}